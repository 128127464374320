import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmDirectionModule } from 'agm-direction';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AllRoutesComponent } from './pages/all-routes/all-routes.component';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AboutComponent } from './pages/about/about.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { ContactComponent } from './pages/contact/contact.component';
import { map } from 'rxjs/operators';
import { OnewayComponent } from './components/oneway/oneway.component';
import { RoundComponent } from './components/roundtrip/roundtrip.component';
import { OutstationComponent } from './components/outstation/outstation.component';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { RentalComponent } from './components/rental/rental.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const id = window.location.pathname.replace(/\//g, "");
  return () => http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
    map(response => {
      config.apiKey = response['mapkey'];
      console.log(response['mapkey']);
      return response;
    })
  ).toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AllRoutesComponent,
    CityDetailComponent,
    AboutComponent,
    TariffComponent,
    ContactComponent,
    OnewayComponent,
    RoundComponent,
    OutstationComponent,
    RentalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDGk6zTd71zfML8F1sQfJ6D9_-1b8N-BvY',
      libraries: ["places"]
    }),
    AgmDirectionModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    CarouselModule,
    MatExpansionModule,
    MatIconModule,
    MatStepperModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }